<template>
  <Layout>
    <div class="container-fluid pt-5" style="margin-bottom: 120px">
      <companyName v-bind:name="company.company.name"/>
      <btn-back/>
      <div class="title mb-2">
        Upload Tax Document
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <BootStrapAlert/>
        </div>

        <div class="row mt-4">
          <div class="section-title">
            Which form have you submitted?
          </div>

          <div class="col-md-2 col-lg-2 mt-3">
            <div class="cursor-pointer" @click="OnClickedRadioBtn('_941')">
              <span class="ico-radio-checked" v-show="formType.type === '_941'"></span>
              <span class="ico-radio-unchecked" v-show="formType.type !== '_941'"></span>
              <span class="form-number">941</span>
            </div>
          </div>
          <div class="col-md-2 col-lg-2 mt-3">
            <div class="cursor-pointer" @click="OnClickedRadioBtn('_943')">
              <span class="ico-radio-checked" v-show="formType.type === '_943'"></span>
              <span class="ico-radio-unchecked" v-show="formType.type !== '_943'"></span>
              <span class="form-number">943</span>
            </div>
          </div>
          <div class="col-md-2 col-lg-2 mt-3">
            <div class="cursor-pointer" @click="OnClickedRadioBtn('_944')">
              <span class="ico-radio-checked" v-show="formType.type === '_944'"></span>
              <span class="ico-radio-unchecked" v-show="formType.type !== '_944'"></span>
              <span class="form-number">944</span>
            </div>
          </div>

          <div class="col-md-2 col-lg-2 mt-3">
            <div class="cursor-pointer" @click="OnClickedRadioBtn('_5884-D')">
              <span class="ico-radio-checked" v-show="formType.type === '_5884-D'"></span>
              <span class="ico-radio-unchecked" v-show="formType.type !== '_5884-D'"></span>
              <span class="form-number">5884-D</span>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="section-title">Upload Your Tax Document</div>
          <div class="section-content">
            File Format: PDF
          </div>
          <div class="section-content">
            File size limit: 2 MB
          </div>
        </div>

        <div class="toggle-container" v-show="formType.type === '_941'">
          <div class="row toggle-header mt-4">
            <div class="col-md-6 col-sm-6 col-6 p-0">
              <div class="text-toggle" v-show="!user.uploadStatus">
                Upload 941 Form
              </div>
              <div class="text-toggle success" v-show="user.uploadStatus">
                <img :src="require('/src/assets/images/ico-upload-success.png')" alt="Upload">
                Upload 941 Form
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-6 text-end pe-0">
              <span class="ico-toggle-minus animate" @click="OnClickedToggleBtn('_form', !toggleType._form)" v-show="toggleType._form"></span>
              <span class="ico-toggle-plus animate" @click="OnClickedToggleBtn('_form', !toggleType._form)" v-show="!toggleType._form"></span>
            </div>
          </div>
          <div class="d-none">
            <form id="uploadForm941">
              <input class="form-control" name="files[]" type="file" multiple />
            </form>
          </div>
          <div class="toggle-body collapseElement" :class="{collapsed : !toggleType._form}">
            <div class="row">
              <div class="header-year mt-4">
                2020
              </div>
              <div class="col-md-2 col-lg-2 col-12">
                <div class="item">
                  <div class="item-title">Q2 : Apr to Jun</div>
                  <div
                      @click="upload941('2020')"
                      class="item-content">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-lg-2 col-12">
                <div class="item">
                  <div class="item-title">Q3 : Jul to Sep</div>
                  <div
                      @click="upload941('2020')"
                      class="item-content d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-lg-2 col-12">
                <div class="item disable">
                  <div class="item-title">Q4 : Oct to Dec</div>
                  <div class="item-content d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>
            </div>

<!--            <div class="row">-->
<!--              <div class="col-md-12">-->
<!--                <div class="files-list mt-3 mb-3">-->
<!--                  <ul class="list-unstyled">-->
<!--                    <li class="alert alert-primary">-->
<!--                      <a-->
<!--                          @click.prevent-->
<!--                          class="text-decoration-none"-->
<!--                          href="#">-->
<!--                        <span class="me-3">1</span>-->
<!--                        <span class="me-1">555</span>-->
<!--                        <span class="me-3">- Q1</span>-->
<!--                        <span class="me-3 selected">- Q2</span>-->
<!--                        <span class="me-3">- Q3</span>-->
<!--                        <span class="me-3">- Q4</span>-->
<!--                      </a>-->
<!--                      <div class="ms-3 spinner-border" role="status">-->
<!--                        <span class="visually-hidden">Loading...</span>-->
<!--                      </div>-->
<!--                      <span class="ms-3 text-primary upload-ready">-->
<!--                            <font-awesome-icon icon="fa-solid fa-check" />-->
<!--                          </span>-->
<!--                      <button  class="btn btn-delete float-end">-->
<!--                        <font-awesome-icon icon="fa-solid fa-trash-can" />-->
<!--                      </button>-->
<!--                      <button  class="btn btn-delete float-end">-->
<!--                        <font-awesome-icon icon="fa-solid fa-download" />-->
<!--                      </button>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="row">-->
<!--              <div class="col-md-12">-->
<!--                <div v-if="form.forms.filter(o => {-->
<!--                      return o.year === '2020'-->
<!--                    }).length>0" class="files-list mt-3 mb-3">-->
<!--                  <ul class="list-unstyled">-->
<!--                    <li v-for="(item, index) in form.forms.filter(o => {return o.year == '2020'})" :key="index" class="alert alert-primary">-->
<!--                      <a-->
<!--                          @click.prevent-->
<!--                          class="text-decoration-none"-->
<!--                          href="#">-->
<!--                        <span class="me-3">{{ index + 1 }}</span>-->
<!--                        <span class="me-1">{{ item.path.split('/').pop() }}</span>-->
<!--                        <span v-if="item.q1 === ':selected:'" class="me-3">- Q1</span>-->
<!--                        <span v-if="item.q2 === ':selected:'" class="me-3">- Q2</span>-->
<!--                        <span v-if="item.q3 === ':selected:'" class="me-3">- Q3</span>-->
<!--                        <span v-if="item.q4 === ':selected:'" class="me-3">- Q4</span>-->
<!--                      </a>-->
<!--                      <div :class="user.upload2020?'d-none':''" class="ms-3 spinner-border" role="status">-->
<!--                        <span class="visually-hidden">Loading...</span>-->
<!--                      </div>-->
<!--&lt;!&ndash;                      <span :class="user.upload2020?'':'d-none'" class="ms-3 text-primary upload-ready">&ndash;&gt;-->
<!--&lt;!&ndash;                            <font-awesome-icon icon="fa-solid fa-check" />&ndash;&gt;-->
<!--&lt;!&ndash;                          </span>&ndash;&gt;-->
<!--&lt;!&ndash;                      <button :data-fileid="item.fileuuid" :data-uuid="item.uuid" @click="deleteFile" :class="user.upload2020?'':'d-none'" class="btn btn-delete float-end">&ndash;&gt;-->
<!--&lt;!&ndash;                        <font-awesome-icon icon="fa-solid fa-trash-can" />&ndash;&gt;-->
<!--&lt;!&ndash;                      </button>&ndash;&gt;-->
<!--&lt;!&ndash;                      <button :data-id="item.uuid" @click="createPDF(item.uuid)" :class="user.upload2020?'':'d-none'" class="btn btn-delete float-end">&ndash;&gt;-->
<!--&lt;!&ndash;                        <font-awesome-icon icon="fa-solid fa-download" />&ndash;&gt;-->
<!--&lt;!&ndash;                      </button>&ndash;&gt;-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

            <div class="row">
              <div class="header-year mt-4">
                2021
              </div>
              <div class="col-md-2 col-lg-2 col-12">
                <div class="item">
                  <div class="item-title">Q1 : Jan to Mar</div>
                  <div
                      @click="upload941('2021')"
                      class="item-content d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-lg-2 col-12">
                <div class="item">
                  <div class="item-title">Q2 : Apr to Jun</div>
                  <div
                      @click="upload941('2021')"
                      class="item-content d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-lg-2 col-12">
                <div class="item">
                  <div class="item-title">Q3 : Jul to Sep</div>
                  <div
                      @click="upload941('2021')"
                      class="item-content d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-lg-2 col-12">
                <div class="item disable">
                  <div class="item-title">Q4 : Oct to Dec</div>
                  <div class="item-content d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>
            </div>

<!--            <div class="row">-->
<!--              <div class="col-md-12">-->
<!--                <div v-if="form.forms.filter(o => {-->
<!--                      return o.year === '2021'-->
<!--                    }).length>0" class="files-list mt-3 mb-3">-->
<!--                  <ul class="list-unstyled">-->
<!--                    <li v-for="(item, index) in form.forms.filter(o => {return o.year == '2021'})" :key="index" class="alert alert-primary">-->
<!--                      <a-->
<!--                          @click.prevent-->
<!--                          class="text-decoration-none"-->
<!--                          href="#">-->
<!--                        <span class="me-3">{{ index + 1 }}</span>-->
<!--                        <span class="me-1">{{ item.path.split('/').pop() }}</span>-->
<!--                        <span v-if="item.q1 === ':selected:'" class="me-3">- Q1</span>-->
<!--                        <span v-if="item.q2 === ':selected:'" class="me-3">- Q2</span>-->
<!--                        <span v-if="item.q3 === ':selected:'" class="me-3">- Q3</span>-->
<!--                        <span v-if="item.q4 === ':selected:'" class="me-3">- Q4</span>-->
<!--                      </a>-->
<!--                      <div :class="user.upload2021?'d-none':''" class="ms-3 spinner-border" role="status">-->
<!--                        <span class="visually-hidden">Loading...</span>-->
<!--                      </div>-->
<!--                      <span :class="user.upload2021?'':'d-none'" class="ms-3 text-primary upload-ready">-->
<!--                            <font-awesome-icon icon="fa-solid fa-check" />-->
<!--                          </span>-->
<!--                      <button :data-id="item.fileuuid" @click="deleteFile" :class="user.upload2021?'':'d-none'" class="btn btn-delete float-end">-->
<!--                        <font-awesome-icon icon="fa-solid fa-trash-can" />-->
<!--                      </button>-->
<!--                      <button :data-id="item.uuid" @click="createPDF(item.uuid)" :class="user.upload2021?'':'d-none'" class="btn btn-delete float-end">-->
<!--                        <font-awesome-icon icon="fa-solid fa-download" />-->
<!--                      </button>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>

        <div class="toggle-container" v-show="formType.type === '_943'">
          <div class="row toggle-header mt-4">
            <div class="col-md-6 col-sm-6 col-6 p-0">
              <div class="text-toggle">
                Upload 943 Form
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-6 text-end pe-0">
              <span class="ico-toggle-minus animate" @click="OnClickedToggleBtn('_form', !toggleType._form)" v-show="toggleType._form"></span>
              <span class="ico-toggle-plus animate" @click="OnClickedToggleBtn('_form', !toggleType._form)" v-show="!toggleType._form"></span>
            </div>
          </div>

          <div class="toggle-body collapseElement" :class="{collapsed : !toggleType._form}">
            <div class="row">
              <div class="col-md-6">
                <div class="header-year mt-4">
                  FORM 943
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 d-md-flex d-lg-flex justify-content-between">
                <div class="col-md-4 col-lg-4 col-12">
                  <div class="item">
                    <div class="item-title">2020</div>
                    <div class="item-content d-flex">
                      <span class="ico-file-plus"></span>
                      <span class="color-blue">Browse&nbsp;</span>your file
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4 col-12">
                  <div class="item">
                    <div class="item-title">2021</div>
                    <div class="item-content d-flex">
                      <span class="ico-file-plus"></span>
                      <span class="color-blue">Browse&nbsp;</span>your file
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="toggle-container" v-show="formType.type === '_944'">
          <div class="row toggle-header mt-4">
            <div class="col-md-6 col-sm-6 col-6 p-0">
              <div class="text-toggle">
                Upload 944 Form
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-6 text-end pe-0">
              <span class="ico-toggle-minus animate" @click="OnClickedToggleBtn('_form', !toggleType._form)" v-show="toggleType._form"></span>
              <span class="ico-toggle-plus animate" @click="OnClickedToggleBtn('_form', !toggleType._form)" v-show="!toggleType._form"></span>
            </div>
          </div>

          <div class="toggle-body collapseElement" :class="{collapsed : !toggleType._form}">
            <div class="row">
              <div class="col-md-6">
                <div class="header-year mt-4">
                  FORM 944
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 d-md-flex d-lg-flex justify-content-between">
                <div class="col-md-4 col-lg-4 col-12">
                  <div class="item">
                    <div class="item-title">2020</div>
                    <div class="item-content d-flex">
                      <span class="ico-file-plus"></span>
                      <span class="color-blue">Browse&nbsp;</span>your file
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4 col-12">
                  <div class="item">
                    <div class="item-title">2021</div>
                    <div class="item-content d-flex">
                      <span class="ico-file-plus"></span>
                      <span class="color-blue">Browse&nbsp;</span>your file
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="toggle-container" v-show="formType.type === '_5884-D'">
          <div class="row toggle-header mt-4">
            <div class="col-md-8 col-sm-8 col-8 p-0">
              <div class="text-toggle">
                Upload ERC for Tax Exempt Organizations 5884-D [if applicable]
              </div>
<!--              <div class="text-toggle danger">-->
<!--                <img src="/src/assets/imgs/ico-upload-danger.png" alt="">-->
<!--                Upload ERC for Tax Exempt Organizations 5884-D [if applicable]-->
<!--              </div>-->
            </div>
            <div class="col-md-4 col-sm-4 col-4 text-end pe-0">
              <span class="ico-toggle-minus animate" @click="OnClickedToggleBtn('_erc', !toggleType._erc)" v-show="toggleType._erc"></span>
              <span class="ico-toggle-plus animate" @click="OnClickedToggleBtn('_erc', !toggleType._erc)" v-show="!toggleType._erc"></span>
            </div>
          </div>

          <div class="toggle-body collapseElement" :class="{collapsed : !toggleType._erc}">
            <div class="row">
              <div class="header-year mt-4">
                2020
              </div>
              <div class="col-md-2 col-lg-2 col-12">
                <div class="item">
                  <div class="item-title">Q2 : Apr to Jun</div>
                  <div class="item-content danger d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-lg-2 col-12">
                <div class="item">
                  <div class="item-title">Q3 : Jul to Sep</div>
                  <div class="item-content d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-lg-2 col-12">
                <div class="item disable">
                  <div class="item-title">Q4 : Oct to Dec</div>
                  <div class="item-content d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="header-year mt-4">
                2021
              </div>
              <div class="col-md-2 col-lg-2 col-12">
                <div class="item">
                  <div class="item-title">Q1 : Jan to Mar</div>
                  <div class="item-content d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-lg-2 col-12">
                <div class="item">
                  <div class="item-title">Q2 : Apr to Jun</div>
                  <div class="item-content danger d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-lg-2 col-12">
                <div class="item">
                  <div class="item-title">Q3 : Jul to Sep</div>
                  <div class="item-content d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>

              <div class="col-md-2 col-lg-2 col-12">
                <div class="item disable">
                  <div class="item-title">Q4 : Oct to Dec</div>
                  <div class="item-content d-flex">
                    <span class="ico-file-plus"></span>
                    <span class="color-blue">Browse&nbsp;</span>your file
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script setup>
import Layout from "@/layouts/main";
import BootStrapAlert from "@/components/utils/AlertPinia";
import btnBack from "@/components/btn-back/btnBack";

import companyName from "@/components/company-name/company-name";
import {companyStore} from "@/store/company";
const company = companyStore();

import {useRoute} from 'vue-router'
import {userStore} from "@/store/user";
import $ from 'jquery'
import {reactive} from 'vue'

const user = userStore();
const route = useRoute();
console.log($, route);
const formType = reactive({
  'type': '_941'
});

const toggleType = reactive({
  '_form': false,
  '_x': false,
  '_cobra': false,
  '_erc': false,
});

function OnClickedRadioBtn(type) {
  formType.type = type;
}

function OnClickedToggleBtn(type, bool) {
  switch (type) {
    case '_form':
      toggleType._form = bool;
      break;
    case '_x':
      toggleType._x = bool;
      break;
    case '_cobra':
      toggleType._cobra = bool;
      break;
    case '_erc':
      toggleType._erc = bool;
      break;
  }
}


function upload941(year) {
  console.log(year);
}


</script>

<style scoped lang="scss" src="./uploadtaxdocument.scss"></style>